import TextField from '@material-ui/core/TextField'
import { Component, createElement } from 'react'

const isStateLess = (Component) =>
  !(Component.prototype && Component.prototype.render)

/**
 * Creates a component class that renders the given Material UI component
 *
 * @param MaterialUIComponent The material ui component to render
 * @param mapProps A mapping of props provided by redux-form to the props the Material UI
 * component needs
 */
function createComponent(MaterialUIComponent, mapProps) {
  class InputComponent extends Component {
    getRenderedComponent() {
      return this.component
    }

    render() {
      return createElement(MaterialUIComponent, {
        ...mapProps(this.props),
        ref: !isStateLess(MaterialUIComponent)
          ? (el) => (this.component = el)
          : null,
      })
    }
  }
  InputComponent.displayName = `ReduxFormMaterialUI${MaterialUIComponent.name}`
  return InputComponent
}

const mapError = ({
  hasHelperText = true,
  meta: { touched, error, warning } = {},
  input,
  ...props
}) => {
  const errorProps =
    touched && (error || warning)
      ? {
          ...props,
          ...input,
          error: Boolean(error || warning),
        }
      : { ...input, ...props }

  if (touched && hasHelperText && (error || warning)) {
    errorProps.helperText = error || warning
  }

  return errorProps
}

export default createComponent(TextField, ({ defaultValue, ...props }) =>
  mapError(props)
)
